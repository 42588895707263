<template>
  <v-container class="container-form">
    <BackArrow class="mt-8" />
    <v-subheader class="title mt-n4 mb-4 pa-0 black--text">
      {{ id ? "Editar Desconto" : "Cadastrar Desconto" }}
    </v-subheader>

    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      autocomplete="off"
      @submit.prevent
    >
      <v-card class="mb-5">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Tipo de Desconto</span>
        </v-card-title>

        <v-card-text class="px-7">
          <v-row>
            <v-col cols="12">
              <v-radio-group
                v-model="dados.tipo_desconto"
                row
                @change="
                  dados.tipo_desconto == 'Trecho'
                    ? loader('localidades')
                    : dados.tipo_desconto == 'Trecho + Linha'
                    ? loaderLines(dados.id_linha)
                    : null
                "
              >
                <v-radio
                  class="mt-3"
                  v-for="(option, i) in options"
                  :key="i"
                  :label="option"
                  :value="option"
                  color="primary"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card
        :class="`mb-${
          dados.tipo_desconto == 'Poltrona + Trecho' ||
          dados.tipo_desconto == 'Poltrona + Tipo de Serviço'
            ? '5'
            : '15'
        }`"
      >
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Descontos</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>

        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="6">
              <span class="primary--text">Tipo de Venda *</span>
              <v-autocomplete
                v-model="dados.id_tipo_venda"
                :items="tiposvenda"
                item-text="descricao"
                item-value="id"
                required
                placeholder="Informe o tipo de venda"
                class="mt-1 custom-solo-selection"
                solo
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="primary--text">Empresa *</span>
              <v-autocomplete
                v-model="dados.id_empresa"
                :items="empresas"
                item-text="nome_fantasia"
                item-value="id"
                required
                placeholder="Informe a empresa"
                class="mt-1 custom-solo-selection"
                solo
                :disabled="dados.tipo_desconto == 'Empresa' ? false : true"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="primary--text">Agência *</span>
              <v-autocomplete
                v-model="dados.id_agencia"
                :items="agencias"
                item-text="nome"
                item-value="id"
                required
                placeholder="Informe a agência"
                class="mt-1 custom-solo-selection"
                solo
                :disabled="dados.tipo_desconto == 'Agência' ? false : true"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="primary--text">Linha *</span>
              <v-autocomplete
                v-model="dados.id_linha"
                :items="linhas"
                item-text="descricao"
                item-value="id"
                required
                placeholder="Informe a linha"
                class="mt-1 custom-solo-selection"
                solo
                @change="
                  dados.tipo_desconto == 'Trecho + Linha'
                    ? loaderLines(dados.id_linha)
                    : null
                "
                :disabled="
                  dados.tipo_desconto == 'Linha' ||
                  dados.tipo_desconto == 'Trecho + Linha'
                    ? false
                    : dados.tipo_desconto == 'Horário + Linha'
                    ? false
                    : true
                "
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="primary--text">Localidade de Origem *</span>
              <v-autocomplete
                v-model="dados.id_localidade_origem"
                :items="localidades"
                item-text="nome"
                item-value="id"
                required
                placeholder="Informe a localidade de origem"
                class="mt-1 custom-solo-selection"
                solo
                :disabled="
                  dados.tipo_desconto == 'Empresa'
                    ? true
                    : dados.tipo_desconto == 'Agência'
                    ? true
                    : dados.tipo_desconto == 'Linha'
                    ? true
                    : dados.tipo_desconto == 'Destino'
                    ? true
                    : dados.tipo_desconto == 'Horário da Viagem'
                    ? true
                    : dados.tipo_desconto == 'Horário + Linha'
                    ? true
                    : dados.tipo_desconto == 'Poltrona + Tipo de Serviço'
                    ? true
                    : false
                "
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="primary--text">Localidade de Destino *</span>
              <v-autocomplete
                v-model="dados.id_localidade_destino"
                :items="localidades"
                item-text="nome"
                item-value="id"
                required
                placeholder="Informe a localidade de destino"
                class="mt-1 custom-solo-selection"
                solo
                :disabled="
                  dados.tipo_desconto == 'Empresa'
                    ? true
                    : dados.tipo_desconto == 'Agência'
                    ? true
                    : dados.tipo_desconto == 'Linha'
                    ? true
                    : dados.tipo_desconto == 'Origem'
                    ? true
                    : dados.tipo_desconto == 'Horário da Viagem'
                    ? true
                    : dados.tipo_desconto == 'Horário + Linha'
                    ? true
                    : dados.tipo_desconto == 'Poltrona + Tipo de Serviço'
                    ? true
                    : false
                "
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              v-if="dados.tipo_desconto == 'Conexão + Trecho' ? true : false"
            >
              <span class="primary--text"
                >Localidade de Origem Final (Conexão) *</span
              >
              <v-autocomplete
                v-model="dados.id_localidade_origem_final"
                :items="localidades"
                item-text="nome"
                item-value="id"
                required
                placeholder="Informe a localidade de origem final (Conexão)"
                class="mt-1 custom-solo-selection"
                solo
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              v-if="dados.tipo_desconto == 'Conexão + Trecho' ? true : false"
            >
              <span class="primary--text"
                >Localidade de Destino Final (Conexão) *</span
              >
              <v-autocomplete
                v-model="dados.id_localidade_destino_final"
                :items="localidades"
                item-text="nome"
                item-value="id"
                required
                placeholder="Informe a localidade de destino final (Conexão)"
                class="mt-1 custom-solo-selection"
                solo
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="primary--text">Dias de Viagem</span>
              <v-select
                item-value="cod"
                item-text="dia"
                v-model="dados.dias_semana"
                :items="[
                  { dia: 'SEG', cod: '1' },
                  { dia: 'TER', cod: '2' },
                  { dia: 'QUA', cod: '3' },
                  { dia: 'QUI', cod: '4' },
                  { dia: 'SEX', cod: '5' },
                  { dia: 'SÁB', cod: '6' },
                  { dia: 'DOM', cod: '0' },
                ]"
                multiple
                placeholder="Informe os dias de viagem"
                class="mt-1 custom-solo-selection"
                solo
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="primary--text">Hora de Início</span>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="dados.hora_inicial"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dados.hora_inicial"
                    append-icon="mdi-clock-outline"
                    readonly
                    v-on="on"
                    placeholder="Informe a hora de início"
                    class="mt-n1"
                    :disabled="
                      dados.tipo_desconto == 'Empresa'
                        ? true
                        : dados.tipo_desconto == 'Agência'
                        ? true
                        : dados.tipo_desconto == 'Linha'
                        ? true
                        : dados.tipo_desconto == 'Trecho'
                        ? true
                        : dados.tipo_desconto == 'Trecho + Linha'
                        ? true
                        : dados.tipo_desconto == 'Origem'
                        ? true
                        : dados.tipo_desconto == 'Destino'
                        ? true
                        : dados.tipo_desconto == 'Ida/Volta + Trecho'
                        ? true
                        : dados.tipo_desconto == 'Tipo de Serviço + Trecho'
                        ? true
                        : dados.tipo_desconto == 'Poltrona + Tipo de Serviço'
                        ? true
                        : dados.tipo_desconto == 'Poltrona + Trecho'
                        ? true
                        : dados.tipo_desconto == 'Conexão + Trecho'
                        ? true
                        : false
                    "
                  ></v-text-field>
                </template>
                <v-time-picker
                  scrollable
                  format="24hr"
                  v-if="menu"
                  v-model="dados.hora_inicial"
                  full-width
                  @click:minute="$refs.menu.save(dados.hora_inicial)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="primary--text">Hora de Término</span>
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="dados.hora_final"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dados.hora_final"
                    append-icon="mdi-clock-outline"
                    readonly
                    v-on="on"
                    placeholder="Informe a hora de término"
                    class="mt-n1"
                    :disabled="
                      dados.tipo_desconto == 'Empresa'
                        ? true
                        : dados.tipo_desconto == 'Agência'
                        ? true
                        : dados.tipo_desconto == 'Linha'
                        ? true
                        : dados.tipo_desconto == 'Trecho'
                        ? true
                        : dados.tipo_desconto == 'Trecho + Linha'
                        ? true
                        : dados.tipo_desconto == 'Origem'
                        ? true
                        : dados.tipo_desconto == 'Destino'
                        ? true
                        : dados.tipo_desconto == 'Ida/Volta + Trecho'
                        ? true
                        : dados.tipo_desconto == 'Tipo de Serviço + Trecho'
                        ? true
                        : dados.tipo_desconto == 'Poltrona + Tipo de Serviço'
                        ? true
                        : dados.tipo_desconto == 'Poltrona + Trecho'
                        ? true
                        : dados.tipo_desconto == 'Conexão + Trecho'
                        ? true
                        : false
                    "
                  ></v-text-field>
                </template>
                <v-time-picker
                  scrollable
                  format="24hr"
                  v-if="menu1"
                  v-model="dados.hora_final"
                  full-width
                  @click:minute="$refs.menu1.save(dados.hora_final)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="primary--text">Data Inicial</span>
              <v-text-field
                v-model="dados.data_inicial"
                required
                placeholder="Informe a data inicial"
                class="mt-n1"
                v-mask-date.br
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="primary--text">Data Final</span>
              <v-text-field
                v-model="dados.data_final"
                required
                placeholder="Informe a data final"
                class="mt-n1"
                v-mask-date.br
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="primary--text">Tipo de Desconto</span>
              <v-select
                item-value="cod"
                item-text="tipo"
                v-model="dados.desconto"
                :items="[
                  { cod: '1', tipo: 'Moeda R$' },
                  { cod: '2', tipo: 'Porcentagem %' },
                ]"
                placeholder="Informe o tipo de desconto"
                class="mt-1 custom-solo-selection"
                solo
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="primary--text">Forma de Pagamento *</span>
              <v-autocomplete
                v-model="dados.id_formapagamento"
                :items="formaspagamento"
                item-text="descricao"
                item-value="id"
                required
                placeholder="Informe a forma de pagamento"
                class="mt-1 custom-solo-selection"
                solo
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="5">
              <span class="primary--text">Tipo de Serviço *</span>
              <v-autocomplete
                v-model="dados.id_tipo_servico"
                :items="tiposservico"
                item-text="tipo_servico"
                item-value="id"
                required
                placeholder="Informe o tipo de serviço"
                class="mt-1 custom-solo-selection"
                solo
                :disabled="
                  dados.tipo_desconto == 'Tipo de Serviço + Trecho' ||
                  dados.tipo_desconto == 'Poltrona + Tipo de Serviço' ||
                  dados.tipo_desconto == 'Conexão + Trecho'
                    ? false
                    : true
                "
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="primary--text">{{
                dados.desconto == 1 ? "Valor R$ *" : "Percentual %"
              }}</span>
              <v-text-field
                v-model="dados.valor"
                required
                v-mask-decimal.br="2"
                :placeholder="`Informe o ${
                  dados.desconto == 1 ? 'valor' : 'percentual'
                }`"
                class="mt-n1"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card
        class="mb-15"
        v-if="
          dados.tipo_desconto == 'Poltrona + Trecho' ||
          dados.tipo_desconto == 'Poltrona + Tipo de Serviço'
            ? true
            : false
        "
      >
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Poltronas</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>

        <v-card-text class="pt-7 px-7">
          <v-row dense>
            <v-col cols="12" sm="2">
              <span class="primary--text">Número</span>
              <v-text-field
                v-model="numero"
                v-mask="'00'"
                placeholder="Informe o número"
                class="mt-n1"
                @keypress.enter="addNumber(numero)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-chip
                v-for="(numeros, i) in dados.poltronas"
                :key="i"
                close
                @click:close="removeNumber(i)"
                color="primary"
                class="mr-2"
                >{{ numeros }}</v-chip
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-footer color="primary" class="rounded-t footer-form" absolute>
        <div class="flex-grow-1"></div>
        <v-btn depressed dark text :disabled="!valid" @click="validate"
          >Confirmar</v-btn
        >
      </v-footer>
    </v-form>
    <v-snackbar
      v-model="message.snacshow"
      :color="message.type"
      :multi-line="message.mode === 'multi-line'"
      :timeout="message.timeout"
      :vertical="message.mode === 'vertical'"
      bottom
    >
      {{ message.text }}
      <v-btn dark text @click="message.snacshow = false">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import BackArrow from "../../../components/BackArrow/index.vue";
export default {
  props: ["id"],
  data() {
    return {
      loading: false,
      menu: false,
      menu1: false,
      numero: 0,
      message: {
        snacshow: false,
        text: "",
        type: "",
        mode: "",
        timeout: 2000,
      },
      dados: {
        id_tipo_venda: null,
        id_empresa: null,
        id_agencia: null,
        id_linha: null,
        id_localidade_origem: null,
        id_localidade_destino: null,
        id_formapagamento: null,
        hora_inicial: null,
        hora_final: null,
        data_inicial: null,
        data_final: null,
        dias_semana: [],
        desconto: "",
        valor: 0,
        tipo_desconto: "",
        id_tipo_servico: null,
        poltronas: [],
      },
      options: [
        "Ida/Volta + Trecho",
        "Tipo de Serviço + Trecho",
        "Poltrona + Trecho",
        "Poltrona + Tipo de Serviço",
        "Conexão + Trecho",
        "Horário + Trecho",
        "Trecho + Linha",
        "Horário + Linha",
        "Horário da Viagem",
        "Empresa",
        "Trecho",
        "Agência",
        "Linha",
      ],
      linhas: [],
      empresas: [],
      agencias: [],
      localidades: [],
      formaspagamento: [],
      tiposservico: [],
      tiposvenda: [],
      valid: true,
      fieldRules: [(v) => !!v || "Este campo é requerido"],
    };
  },
  created() {
    this.loader("empresas");
    this.loader("agencias");
    this.loader("linhas");
    this.loader("localidades");
    this.loader("formaspagamento");
    this.loader("tiposvenda");
    this.loader("tiposservico");
    if (this.id) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .get(`/descontos/${this.id}`)
        .then((r) => {
          this.dados = r.data[0];
          this.dados.poltronas = JSON.parse(this.dados.poltronas);
          this.dados.dias_semana = JSON.parse(this.dados.dias_semana);
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    }
  },
  methods: {
    addNumber(numero) {
      if (this.dados.poltronas.indexOf(numero) == -1 && numero != "") {
        this.dados.poltronas.push(numero);
        this.numero = null;
      } else {
        this.message.text = "Número já cadastrado!";
        this.message.snacshow = true;
        this.message.type = "error";
      }
    },
    removeNumber(indice) {
      this.dados.poltronas.splice(indice, 1);
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.salvar();
      } else {
        this.message.snacshow = true;
        this.message.type = "warning";
        this.message.text =
          "Atencão um ou mais campos precisam ser preenchidos!";
      }
    },
    loader(table) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .get(`/` + table)
        .then((r) => {
          table == "empresas"
            ? (this.empresas = r.data)
            : table == "agencias"
            ? (this.agencias = r.data)
            : table == "linhas"
            ? (this.linhas = r.data)
            : table == "localidades"
            ? (this.localidades = r.data)
            : table == "formaspagamento"
            ? (this.formaspagamento = r.data)
            : table == "tiposvenda"
            ? (this.tiposvenda = r.data)
            : table == "tiposservico"
            ? (this.tiposservico = r.data)
            : [];
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    loaderLines(id) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .get(`/descontos/trechos/${id}`)
        .then((r) => {
          this.localidades = r.data;
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    salvar() {
      this.$store.dispatch("activeLoader", true);
      this.dados.poltronas = JSON.stringify(this.dados.poltronas);
      this.dados.dias_semana = JSON.stringify(this.dados.dias_semana);
      if (this.id) {
        this.$http
          .post(`/descontos/update/${this.id}`, this.dados) // link da API
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
              setTimeout(() => {
                this.$router.push("/preco/descontos/gerenciar"); // Link da rota
              }, 500);
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      } else {
        this.$http
          .post("/descontos/create", this.dados)
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
              setTimeout(() => {
                this.$router.push("/preco/descontos/gerenciar");
              }, 1500);
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      }
    },
  },
  components: { BackArrow },
};
</script>

<style src="../style.vue"></style>
